@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@layer base {
    :root {
        --background: 25 100% 95%;
        --foreground: 25 5% 0%;
        --card: 25 50% 90%;
        --card-foreground: 25 5% 10%;
        --popover: 25 100% 95%;
        --popover-foreground: 25 100% 0%;
        --primary: 25 87.6% 52.5%;
        --primary-foreground: 0 0% 100%;
        --secondary: 25 30% 70%;
        --secondary-foreground: 0 0% 0%;
        --muted: -13 30% 85%;
        --muted-foreground: 25 5% 35%;
        --accent: -13 30% 80%;
        --accent-foreground: 25 5% 10%;
        --destructive: 0 100% 30%;
        --destructive-foreground: 25 5% 90%;
        --border: 25 30% 50%;
        --input: 25 30% 18%;
        --ring: 25 87.6% 52.5%;
        --radius: 0.5rem;
    }
    .dark {
        --background: 25 50% 5%;
        --foreground: 25 5% 90%;
        --card: 25 50% 0%;
        --card-foreground: 25 5% 90%;
        --popover: 25 50% 5%;
        --popover-foreground: 25 5% 90%;
        --primary: 25 87.6% 52.5%;
        --primary-foreground: 0 0% 100%;
        --secondary: 25 30% 10%;
        --secondary-foreground: 0 0% 100%;
        --muted: -13 30% 15%;
        --muted-foreground: 25 5% 60%;
        --accent: -13 30% 15%;
        --accent-foreground: 25 5% 90%;
        --destructive: 0 100% 30%;
        --destructive-foreground: 25 5% 90%;
        --border: 25 30% 18%;
        --input: 25 30% 18%;
        --ring: 25 87.6% 52.5%;
        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings:
            "rlig" 1,
            "calt" 1;
    }
}

.hero-bg {
    background-image: url("./assets/img/hero-bg.png");
    background-size: cover;
    background-position: center center;
}

.hiw-bg {
    background-image: url("./assets/img/girl_phone_full.jpg");
    background-size: cover;
    background-position: center center;
}

.text-shadow {
    text-shadow: 1px 2px 2px #00000063;
}

@media (max-width: 1250px) {
    .hiw-bg {
        background-image: url("./assets/img/girl_phone.jpeg");
    }

    .text-shadow {
        text-shadow: 1px 2px 2px #000000d3;
    }
}
